import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
// @ts-ignore
import Retool from 'react-retool';
import { updateNonNullChain } from 'typescript';

// const url = 'https://admin.wellinks.com/apps/6bb5cf8e-2c96-11ee-b281-dbea3f4f5c8b/TriagePortal';
// const sample = { name: 'Sample data' }
// const mockFunction = (data: any) => {
//     console.log(data);
// }

const url = 'https://admin.wellinks.com/apps/TriagePortal_embed?_environment=staging&_embed=true&_hideNav=true';
const data = {
  patientID: null
};

function App() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight); // Update window height on resize
    };

    window.addEventListener('resize', handleResize); // Listen for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup the event listener
    };
  }, []);


 
  useEffect(() => {
    const handleMessage = (e: any) => {
        if (e?.data?.patientId && e?.data?.episodeId) {
          console.log("Hello from handle message:",e.data);
            // navigate(`/patients/${e.data.patientId}/clinical-alerts`, { state: { episodeID: e.data.episodeId } });
        }
    };
  
    window.addEventListener('message', handleMessage);
  
    return () => {
      window.removeEventListener('message', handleMessage);
    };
}, []);

  return (
    <Retool height={`${windowHeight}px`} width="100%" url={url} data={data} />
  );
}

export default App;


